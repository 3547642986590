import React from 'react';

import _1 from '../../img/pillars/1.png';
import _2 from '../../img/pillars/2.png';
import Tile from '../Tiles';
import Button from '../Button';

interface IPillar {
  onClick: (source: string) => {};
}

const Pillar: React.FC<IPillar> = ({ onClick }) => {
  return (
    <div id="pillar">
      <Tile leftImage={_1} rightImage={_2} />
      <div className="back-button">
        <Button
          caption="Back To Branding & Creativity"
          onClick={() => onClick('branding')}
        />
      </div>
    </div>
  );
};

export default Pillar;
