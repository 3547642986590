import React from 'react';

interface NavProps {
  activeLink: string;
  caption: string;
  control: string;
  onClick: () => {};
  isCompany?: boolean;
}

const NavLink: React.FC<NavProps> = ({
  activeLink,
  caption,
  control,
  onClick,
  isCompany = false,
}) => {
  const areas = ['others'];
  if (areas.includes(control)) {
    activeLink = 'practice';
    control = 'practice';
  }
  const active = !isCompany && activeLink === control ? 'active' : undefined;
  const redirect = null; // control === 'home' ? 'index.html' : null;
  return (
    <li className={active} onClick={onClick}>
      <a
        className="nav-link"
        href={redirect}
        style={{ color: isCompany ? '#fff' : undefined }}
      >
        {caption}
      </a>
    </li>
  );
};

export default NavLink;
