import React from 'react';
import Tile from './Tile';

interface IBranding {
  onClick: (source: string) => {};
}

const Branding: React.FC<IBranding> = ({ onClick }) => {
  return (
    <div id="branding" className="section layout_padding wow fadeInUp">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="full">
              <div className="heading_main text_align_center">
                <h2>
                  <span className="orange">Branding & Creativity</span>
                </h2>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Tile
        buttonText="See Wall Art"
        onClick={() => onClick('wallart')}
        description="Wall Art is a paper product called vinyl which can either be
        glossy or matt and coated with resin.Its primarily an indoor
        branding solution."
        caption="Wall Art"
        dockRight
      />
      <Tile
        buttonText="See Window Graphics"
        onClick={() => onClick('window')}
        description="Graphics on your windows is the perfect way to optimize your
        business premises windows.
        Graphics are a great promotional platform and transformational
        solution providing an eye catching sight for passersby to
        attract them into the store."
        caption="Window Graphics"
      />
      <Tile
        buttonText="See Lift Branding"
        onClick={() => onClick('lift')}
        description="Lift branding is a paper product called vinyl which can be either glossy or matte and coated with resin.
        Lift branding is primarily an indoor branding solution for lifts just as the name suggests."
        caption="Lift Branding"
        dockRight
      />
      <Tile
        buttonText="See Pillar Gladding"
        onClick={() => onClick('pillars')}
        description="Gladding adds a distinction to pillars / columns whilst at the
        same time promoting a brand."
        caption="Pillar Gladding"
      />
      <Tile
        buttonText="See Wall Branding"
        onClick={() => onClick('wallBranding')}
        description="Wall branding gives an impressive look.
        However ,wall branding is more than a visual.
        Its an integral part of brand building."
        caption="Wall Branding"
        dockRight
      />
      <Tile
        buttonText="See Signage"
        onClick={() => onClick('signage')}
        description="Use of Signs and symbols to communicate.Can be indoor or outdoors."
        caption="Signage"
      />
      <Tile
        buttonText="See Vehicle Branding"
        onClick={() => onClick('vehicle')}
        description="A branded vehicle is essentially a moving billboard.Vehicle branding can come in the form of full body wrap, half  wrap or vehicle graphics."
        caption="Vehicle Branding"
        dockRight
      />
      <Tile
        buttonText="See Novelties"
        onClick={() => onClick('novelties')}
        description="Corporate promotional merchandise like T-shirts, mugs, umbrellas, office stationery usually giveaways or marketing blitz items."
        caption="Novelties & Other Branding Solutions"
      />
      <Tile
        buttonText="See Pub Ideas"
        onClick={() => onClick('pub')}
        description="The appearance of a bar cannot be over emphasized. 
        Bar appearance can be enhanced through mix of branding solutions i.e table/counter resin, ABS ,snapper frames, text frames ,dye cuts and barrel tables among others."
        caption="Pub Ideas"
        dockRight
      />
      <Tile
        buttonText="See Bar Designs"
        onClick={() => onClick('bar')}
        description=""
        caption="Bar Designs"
      />
    </div>
  );
};

export default Branding;
