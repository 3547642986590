import React from 'react';

import _1 from '../../img/novelties/1.png';
import _2 from '../../img/novelties/2.png';
import _3 from '../../img/novelties/3.png';
import _4 from '../../img/novelties/4.png';
import _5 from '../../img/novelties/5.png';
import _6 from '../../img/novelties/6.png';
import _7 from '../../img/novelties/7.png';
import _8 from '../../img/novelties/8.png';
import _9 from '../../img/novelties/9.png';
import _10 from '../../img/novelties/10.png';

import Tile from '../Tiles';
import Button from '../Button';

interface INovelties {
  onClick: (source: string) => {};
}

const Novelties: React.FC<INovelties> = ({ onClick }) => {
  return (
    <div id="novelties">
      <Tile leftImage={_1} rightImage={_2} />
      <Tile leftImage={_3} rightImage={_4} />
      <Tile leftImage={_5} rightImage={_6} />
      <Tile leftImage={_7} rightImage={_8} />
      <Tile leftImage={_9} rightImage={_10} />
      <div className="back-button">
        <Button
          caption="Back To Branding & Creativity"
          onClick={() => onClick('branding')}
        />
      </div>
    </div>
  );
};

export default Novelties;
