import React from 'react';

import Header from './components/Header';
import Home from './components/Home';
import Footer from './components/Footer';
import Capability from './components/Capability';
import BackTop from './components/BackTop';
import Contacts from './components/Contacts';
import Clients from './components/Clients';
import Practice from './components/Practice';
import Branding from './components/Branding';
import Communications from './components/Communications';
import WallArt from './components/WallArt';
import WindowGraphics from './components/WindowGraphics';
import Pillar from './components/Pillar';
import WallBranding from './components/WallBranding';
import Signage from './components/Signage';
import Disaster from './components/Disaster';
import Vehicle from './components/Vehicle';
import Novelties from './components/Novelties';
import Pub from './components/Pub';
import Bar from './components/Bar';
import Lift from './components/Lift';
import Customer from './components/Customer';

function App() {
  const [activeLink, setActiveLink] = React.useState('home');
  const [component, setComponent] = React.useState(<Home />);

  function onClick(source: string) {
    setActiveLink(source);
    switch (source) {
      case 'capability':
        setComponent(<Capability />);
        break;
      case 'contact':
        setComponent(<Contacts />);
        break;
      case 'clients':
        setComponent(<Clients />);
        break;
      case 'practice':
        setComponent(<Practice onClick={onClick} />);
        break;
      case 'branding':
        setComponent(<Branding onClick={onClick} />);
        break;
      case 'communications':
        setComponent(<Communications onClick={onClick} />);
        break;
      case 'wallart':
        setComponent(<WallArt onClick={onClick} />);
        break;
      case 'window':
        setComponent(<WindowGraphics onClick={onClick} />);
        break;
      case 'pillars':
        setComponent(<Pillar onClick={onClick} />);
        break;
      case 'wallBranding':
        setComponent(<WallBranding onClick={onClick} />);
        break;
      case 'signage':
        setComponent(<Signage onClick={onClick} />);
        break;
      case 'disaster':
        setComponent(<Disaster onClick={onClick} />);
        break;
      case 'vehicle':
        setComponent(<Vehicle onClick={onClick} />);
        break;
      case 'novelties':
        setComponent(<Novelties onClick={onClick} />);
        break;
      case 'pub':
        setComponent(<Pub onClick={onClick} />);
        break;
      case 'bar':
        setComponent(<Bar onClick={onClick} />);
        break;
      case 'lift':
        setComponent(<Lift onClick={onClick} />);
        break;
      case 'customer':
        setComponent(<Customer onClick={onClick} />);
        break;
      default:
        setComponent(<Home />);
        window.location.reload();
        break;
    }
    return {};
  }

  return (
    <>
      <Header onClick={onClick} activeLink={activeLink} />
      {component}
      <Footer />
      <BackTop />
    </>
  );
}

export default App;
