import React from 'react';

import clients from '../../img/brands/clients.png';
import brands from '../../img/brands/brands.png';

const Clients = () => {
  return (
    <div id="client_brands" className="layout_padding">
      <div className="row">
        <div className="col-md-12">
          <div className="full">
            <div className="heading_main text_align_center">
              <h2>
                <span className="orange">Our Clients</span>
              </h2>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-md-6">
            <div className="full text_align_right_img wow-box wow fadeInLeft">
              <img
                src={clients}
                alt="clients"
                className="intro-img bg-white rounded-img"
              />
            </div>
          </div>
          <div className="col-md-6 layout_padding wow-box wow fadeInLeft">
            <div className="full paddding_left_15 bg-white">
              <div className="heading_main text_align_left">
                <h2>
                  <span className="theme_color">Clients</span>
                </h2>
              </div>
            </div>
            <div className="full paddding_left_15 bg-white">
              <p className="orange">
                To us, Clients are an expression of synergistic impact. It is an
                honor and privilege to be of service.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-md-6 layout_padding wow-box wow fadeInLeft">
            <div className="full paddding_left_15 bg-white">
              <div className="heading_main text_align_left">
                <h2>
                  <span className="theme_color">Brands</span>
                </h2>
              </div>
            </div>
            <div className="full paddding_left_15 bg-white">
              <p className="orange">
                Through our Clients we have proudly played a prominent role in
                positioning of some of greatest products in Kenya.
              </p>
            </div>
          </div>
          <div className="col-md-6 wow-box wow fadeInLeft">
            <div className="full text_align_right_img">
              <img
                src={brands}
                alt="brands"
                className="intro-img bg-white rounded-img"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Clients;
