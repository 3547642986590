import React from 'react';

import _1 from '../../img/wallart/1.png';
import _2 from '../../img/wallart/2.png';
import _3 from '../../img/wallart/3.png';
import _4 from '../../img/wallart/4.png';
import _5 from '../../img/wallart/5.png';
import _6 from '../../img/wallart/6.png';
import Tile from '../Tiles';
import Button from '../Button';

interface IWall {
  onClick: (source: string) => {};
}

const WallArt: React.FC<IWall> = ({ onClick }) => {
  return (
    <div id="wallart">
      <Tile leftImage={_1} rightImage={_2} />
      <Tile leftImage={_3} rightImage={_4} />
      <Tile leftImage={_5} rightImage={_6} />
      <div className="back-button">
        <Button
          caption="Back To Branding & Creativity"
          onClick={() => onClick('branding')}
        />
      </div>
    </div>
  );
};

export default WallArt;
