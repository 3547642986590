import React from 'react';
import NavLink from './NavLink';

interface HeaderProps {
  onClick: (source: string) => {};
  activeLink: string;
}

const Header: React.FC<HeaderProps> = ({ onClick, activeLink }) => {
  return (
    <>
      <header className="top-header">
        <div className="header_bottom">
          <div
            className="menu_orange_section"
            style={{ background: '#5f0038' }}
          >
            <nav className="navbar header-nav navbar-expand-lg">
              <div className="menu_section">
                <button
                  className="navbar-toggler"
                  type="button"
                  data-toggle="collapse"
                  data-target="#navbar-wd"
                  aria-controls="navbar-wd"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                >
                  <span></span>
                  <span></span>
                  <span></span>
                </button>
                <div
                  className="collapse navbar-collapse justify-content-end"
                  id="navbar-wd"
                >
                  <ul className="navbar-nav">
                    <NavLink
                      onClick={() => onClick('home')}
                      caption="Mugumo Communications Limited"
                      activeLink={activeLink}
                      control="home"
                      isCompany
                    />
                    <NavLink
                      onClick={() => onClick('home')}
                      caption="Home"
                      activeLink={activeLink}
                      control="home"
                    />
                    <NavLink
                      onClick={() => onClick('capability')}
                      caption="Our Capability"
                      activeLink={activeLink}
                      control="capability"
                    />
                    <NavLink
                      onClick={() => onClick('practice')}
                      caption="Our Practice Areas"
                      activeLink={activeLink}
                      control="practice"
                    />
                    <NavLink
                      onClick={() => onClick('clients')}
                      caption="Clients and Brands"
                      activeLink={activeLink}
                      control="clients"
                    />
                    <NavLink
                      onClick={() => onClick('customer')}
                      caption="Customer Service"
                      activeLink={activeLink}
                      control="customer"
                    />
                    <NavLink
                      onClick={() => onClick('contact')}
                      caption="Contact"
                      activeLink={activeLink}
                      control="contact"
                    />
                  </ul>
                </div>
              </div>
            </nav>
          </div>
        </div>
      </header>
    </>
  );
};

export default Header;
