import React from 'react';

import _1 from '../../img/bar/1.png';
import _2 from '../../img/bar/2.png';
import _3 from '../../img/bar/3.png';
import _4 from '../../img/bar/4.png';
import Tile from '../Tiles';
import Button from '../Button';

interface IBar {
  onClick: (source: string) => {};
}

const Bar: React.FC<IBar> = ({ onClick }) => {
  return (
    <div id="bar">
      <Tile leftImage={_1} rightImage={_2} />
      <Tile leftImage={_3} rightImage={_4} />
      <div className="back-button">
        <Button
          caption="Back To Branding & Creativity"
          onClick={() => onClick('branding')}
        />
      </div>
    </div>
  );
};

export default Bar;
