import React from 'react';
import Button from '../Button';

interface IPractice {
  onClick: (source: string) => {};
}

const Practice: React.FC<IPractice> = ({ onClick }) => {
  return (
    <div id="practice" className="section layout_padding">
      <div className="container fadeInRight">
        <div className="row">
          <div className="col-md-12">
            <div className="full">
              <div className="heading_main text_align_center">
                <h2>
                  <span className="white">Our Practice Areas</span>
                </h2>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-md-6"></div>
          <div className="col-md-6 layout_padding wow-box wow fadeInLeft">
            <div className="full paddding_left_15 bg-white">
              <div className="heading_main">
                <h2>
                  <span className="theme_color">Branding & Creativity</span>
                </h2>
              </div>
              <div className="heading_main">
                <Button
                  caption="See Branding & Creativity"
                  onClick={() => onClick('branding')}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-md-6 layout_padding wow-box wow fadeInRight">
            <div className="full paddding_left_15 bg-white">
              <div className="heading_main">
                <h2>
                  <span className="theme_color">Corporate Communications</span>
                </h2>
              </div>
              <div className="heading_main">
                <Button
                  caption="See Corporate Communications"
                  onClick={() => onClick('communications')}
                />
              </div>
            </div>
          </div>
          <div className="col-md-6 wow-box wow fadeInLeft"></div>
        </div>
      </div>
    </div>
  );
};

export default Practice;
