/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';

const BackTop = () => {
  return (
    <a href="#" className="back-to-top" style={{ display: 'inline' }}>
      <i className="fa fa-chevron-up"></i>
    </a>
  );
};

export default BackTop;
