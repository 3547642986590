import React from 'react';
import Tile from './Tile';

import _1 from '../../img/printers/1.png';
import _2 from '../../img/printers/2.png';
import _3 from '../../img/printers/3.png';
import _4 from '../../img/printers/4.png';
import _5 from '../../img/printers/5.png';
import _6 from '../../img/printers/6.png';
import _7 from '../../img/printers/7.png';
import _8 from '../../img/printers/8.png';
import _9 from '../../img/printers/9.png';
import _10 from '../../img/printers/10.png';
import _11 from '../../img/printers/11.png';

const Capability = () => {
  return (
    <div className="section layout_padding" id="capability">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="full">
              <div className="heading_main text_align_center">
                <h2>
                  <span className="white">Our Capability</span>
                </h2>
              </div>
            </div>
          </div>
        </div>
        <div className="row layout_padding">
          <Tile image={_1} />
          <Tile image={_2} />
          <Tile image={_3} />
        </div>
        <div className="row">
          <Tile image={_4} />
          <Tile image={_5} />
          <Tile image={_6} />
        </div>
        <div className="row layout_padding">
          <Tile image={_7} />
          <Tile image={_8} />
          <Tile image={_9} />
        </div>
        <div className="row">
          <Tile image={_10} />
          <Tile image={_11} />
        </div>
      </div>
    </div>
  );
};

export default Capability;
