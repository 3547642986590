import React from 'react';
import Button from '../Button';

interface ITile {
  onClick: () => {};
  caption: string;
  description: string;
  buttonText: string;
  dockRight?: boolean;
}

const Tile: React.FC<ITile> = ({
  onClick,
  caption,
  description,
  buttonText,
  dockRight = false,
}) => {
  const leftCol = <div className="col-md-6"></div>;
  const rightCol = (
    <div className="col-md-6 layout_padding wow-box wow fadeInLeft">
      <div className="full paddding_left_15 bg-white">
        <div className="heading_main">
          <h2>
            <span className="theme_color">{caption}</span>
          </h2>
        </div>
        <div className="full paddding_15">
          <p className="orange">{description}</p>
        </div>
        <div className="heading_main">
          <Button caption={buttonText} onClick={() => onClick()} />
        </div>
      </div>
    </div>
  );
  return (
    <div className="container">
      <div className="row">
        {dockRight ? (
          <>
            {leftCol}
            {rightCol}
          </>
        ) : (
          <>
            {rightCol}
            {leftCol}
          </>
        )}
      </div>
    </div>
  );
};

export default Tile;
