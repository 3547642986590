import React from 'react';

const Contacts = () => {
  return (
    <section
      id="contact"
      className="wow fadeInUp section layout_padding"
      style={{ visibility: 'visible', animationName: 'fadeInUp' }}
    >
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="full">
              <div className="heading_main text_align_center">
                <h2>
                  Contact <span className="theme_color">Us</span>
                </h2>
              </div>
            </div>
          </div>
        </div>
        <div className="row contact-info">
          <div className="col-md-4">
            <div className="contact-address">
              <i className="ion-ios-box-outline"></i>
              <h3>Address</h3>
              <address>P.O Box 15183 - 00100 Nairobi, Kenya</address>
            </div>
          </div>
          <div className="col-md-4">
            <div className="contact-phone">
              <i className="ion-ios-telephone-outline"></i>
              <h3>Phone Number</h3>
              <p>
                <a href="tel:+254746964419">+254 746 964 419</a>
              </p>
              <p>
                <a href="tel:+254202506010">+254 (20) 250 6010</a>
              </p>
            </div>
          </div>
          <div className="col-md-4">
            <div className="contact-email">
              <i className="ion-ios-email-outline"></i>
              <h3>Email</h3>
              <p>
                <a href="mailto:info@mugumoltd.com">info@mugumoltd.com</a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Contacts;
