import React from 'react';
import Button from '../Button';

import unWomen from '../../img/disaster/1.png';
import resettling from '../../img/disaster/2.png';

interface IDisaster {
  onClick: (source: string) => {};
}

const Disaster: React.FC<IDisaster> = ({ onClick }) => {
  return (
    <div id="communications">
      <div className="container layout_padding">
        <div className="row">
          <div className="col-md-6">
            <div className="full text_align_right_img wow-box wow fadeInLeft"></div>
          </div>
          <div className="col-md-6 layout_padding wow-box wow fadeInLeft">
            <div className="full paddding_left_15 bg-white">
              <div className="heading_main text_align_left">
                <h2>
                  <span className="theme_color">
                    Disaster Recovery & Crisis Management
                  </span>
                </h2>
              </div>
            </div>
            <div className="full paddding_left_15 bg-white">
              <p className="orange">
                Disaster management is the organization and management of
                resources and responsibilities for dealing with all humanitarian
                aspects of emergencies.
                <br />
                At Mugumo Communication we have developed expertise in assisting
                Organization on disaster preparedness, response and recover.
                <br />
                Some of the Clients we have assisted in implementing their
                disaster management strategy incude:- UNWomen and the Government
                of Kenya - the then Ministry of Special Programmes.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="container layout_padding">
        <div className="row">
          <div className="col-md-6  wow-box wow fadeInLeft">
            <div className="full paddding_left_15 bg-white">
              <div className="heading_main text_align_left">
                <h2>
                  <span className="theme_color">Conflict Resolution</span>
                </h2>
              </div>
            </div>
            <div className="full paddding_left_15 bg-white">
              <p className="orange">
                <span className="underline">
                  Situation Room for Dealing with Political Violence
                </span>
                <br />
                <br />
                In 2007 general elections, Kenya experienced the worst election
                violence in its history. To mitigate the situation in the
                succeeding 2013 general elections, UnWomen , assembled a team of
                preeminent women to prevent conflict during elections.
                <br />
                <br />A Situation Room was established and provided women and
                youth organizations with a platform to respond rapidly to
                election-related incidents through a coordinated system. The
                Situation Room was credited with resolving 1200 real time
                election related situations.
              </p>
            </div>
          </div>
          <div className="col-md-6 wow-box wow fadeInLeft">
            <div className="full text_align_right_img">
              <img
                src={unWomen}
                alt="un women"
                className="comms-img rounded-img bg-white"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-md-6 wow-box wow fadeInLeft">
            <div className="full text_align_right_img">
              <img
                src={resettling}
                alt="resettling"
                className="comms-img rounded-img bg-white"
              />
            </div>
          </div>
          <div className="col-md-6  wow-box wow fadeInLeft">
            <div className="full paddding_left_15 bg-white">
              <div className="heading_main text_align_left">
                <h2>
                  <span className="theme_color">Resettlement</span>
                </h2>
              </div>
            </div>
            <div className="full paddding_left_15 bg-white">
              <p className="orange">
                <span className="underline">
                  Resettling of Internally Displaced Persons.
                </span>
                <br />
                <br />
                The 2007/08 election violence left many Kenyans internally
                displaced.
                <br />
                The Government of Kenya engaged in a resettlement exercise to
                provide decent accommodation for the internally displaced
                persons.
                <br />
                The resettlement programme also entailed equipping the resettled
                persons with life skills so as enable them re-establish
                themselves financially.
                <br />
                Mugumo Communication was retained by the Ministry of Special
                Programmes to oversee IDPs resettlement in the Rift Valley.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="back-button">
        <Button
          caption="Back To Our Practice Areas"
          onClick={() => onClick('practice')}
        />
      </div>
    </div>
  );
};

export default Disaster;
