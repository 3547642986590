import React from 'react';

interface ButtonProps {
  caption: string;
  onClick: () => {};
}

const Button: React.FC<ButtonProps> = ({ caption, onClick }) => {
  return (
    <button onClick={onClick} className="button">
      {caption}
    </button>
  );
};

export default Button;
