import React from 'react';

import mission from '../../img/home/mission.png';
import vision from '../../img/home/vision.png';

const Home = () => {
  return (
    <>
      <div id="homeIntro" className="section about_bg fadeInUp wow ">
        <div id="defination" className="row">
          <div className="col-md-4"></div>
          <div className="col-md-8 bg-white  wow-box wow fadeInRight">
            <div className="full paddding_left_15">
              <div className="heading_main text_align_left">
                <h2>
                  Our
                  <span className="theme_color"> Definition</span>
                </h2>
              </div>
            </div>
            <div className="full paddding_left_15">
              <p className="orange">
                We are a Kenyan company based in Nairobi.
                <br />
                We have served our clients diligently since 2008.
                <br />A major player in branding and communications space, we
                provide our clients with one stop shop for all matters branding,
                marketing and strategic communications.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="section orange fadeInUp wow">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="full text_align_right_img  wow-box wow fadeInLeft">
                <img
                  src={mission}
                  alt="mission"
                  className="intro-img rounded-img"
                />
              </div>
            </div>
            <div className="col-md-6 layout_padding" id="our_mission">
              <div className="full paddding_left_15">
                <div className="heading_main text_align_left">
                  <h2>
                    <span className="orange">Our</span>
                    <span className="theme_color"> Mission</span>
                  </h2>
                </div>
              </div>
              <div className="full paddding_left_15">
                <p className="orange">
                  To be a leader in offering innovative branding and
                  communication solutions.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="section orange fadeInUp wow">
        <div className="container">
          <div className="row">
            <div className="col-md-6 layout_padding">
              <div className="full paddding_left_15">
                <div className="heading_main text_align_left">
                  <h2>
                    <span className="orange">Our</span>
                    <span className="theme_color"> Vision</span>
                  </h2>
                </div>
              </div>
              <div className="full paddding_left_15">
                <p className="orange">
                  To be an integrated Corporate Branding and Communication firm
                  that is endeavoured to offer creative and innovative solutions
                  to our Clients cost effectively.
                </p>
              </div>
            </div>
            <div className="col-md-6">
              <div className="full text_align_right_img">
                <img
                  src={vision}
                  alt="vision"
                  className="intro-img rounded-img wow-box wow fadeInLeft"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;
