import React from 'react';

const Footer = () => {
  function openExternalLink(link: string) {
    if (link) {
      const win = window.open(link, '_blank');
      win.opener = null;
      win.focus();
    }
  }
  return (
    <div className="footer_bottom">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <p className="crp">© Mugumo Communication Limited.</p>
            <div className="socials">
              <i
                className="fa fa-instagram"
                onClick={() =>
                  openExternalLink(
                    'https://www.instagram.com/mugumocommunications/',
                  )
                }
              />
              <i
                className="fa fa-twitter"
                onClick={() =>
                  openExternalLink('https://twitter.com/limitedmugumo')
                }
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
