import React from 'react';

interface TileProps {
  image: string;
}

const Tile: React.FC<TileProps> = ({ image }) => {
  return (
    <div
      className="col-md-4 col-sm-6 col-xs-12 wow-box wow fadeInLeft"
      style={{ visibility: 'visible', animationName: 'fadeInLeft' }}
    >
      <img className="capability rounded-img" src={image} alt="capability" />
    </div>
  );
};

export default Tile;
