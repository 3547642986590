import React from 'react';
import Button from '../Button';

import safariBet from '../../img/comms/safari_bet.png';
import dalbitOil from '../../img/comms/dalbit_oil.png';
import heForShe from '../../img/comms/heforshe.png';
import kncci from '../../img/comms/kncci.png';
import press from '../../img/comms/press.png';
import unWomen from '../../img/disaster/1.png';
import resettling from '../../img/disaster/2.png';

interface IComms {
  onClick: (source: string) => {};
}

const Communications: React.FC<IComms> = ({ onClick }) => {
  return (
    <div id="communications">
      <div className="container layout_padding">
        <div className="row">
          <div className="col-md-6">
            <div className="full text_align_right_img wow-box wow fadeInLeft"></div>
          </div>
          <div className="col-md-6 layout_padding wow-box wow fadeInLeft">
            <div className="full paddding_left_15 bg-white">
              <div className="heading_main text_align_left">
                <h2>
                  <span className="theme_color">Corporate Communication</span>
                </h2>
              </div>
            </div>
            <div className="full paddding_left_15 bg-white">
              <p className="orange">
                Communication within an organization as well as with external
                stakeholders can influence the perception of an organization’s
                brand.
                <br />
                Mugumo Communications guides clients to structure their
                corporate communications from message transmission to brand
                ambassadorial role.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="container layout_padding">
        <div className="row">
          <div className="col-md-6  wow-box wow fadeInLeft">
            <div className="full paddding_left_15 bg-white">
              <div className="heading_main text_align_left">
                <h2>
                  <span className="theme_color">SafariBet</span>
                </h2>
              </div>
            </div>
            <div className="full paddding_left_15 bg-white">
              <p className="orange">
                <span className="underline">Assignment: Activation.</span>
                <br />
                <br />
                SafariBet is a Kenyan betting company.
                <br />
                They retained Mugumo to carry out an activation campaign.
                <br />
                <br />
                <span className="underline">
                  Tasks: Conducting urban centres and bar activations.
                </span>
                <br />
                <br />
                Media (print and radio)
                <br />
                Visibility (street light banners)
                <br />
                Novelties (T-shirt, reflector jackets,Caps)
              </p>
            </div>
          </div>
          <div className="col-md-6 wow-box wow fadeInLeft">
            <div className="full text_align_right_img">
              <img
                src={safariBet}
                alt="safari bet"
                className="comms-img rounded-img bg-white"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-md-6 wow-box wow fadeInLeft">
            <div className="full text_align_right_img">
              <img
                src={dalbitOil}
                alt="dalbit oil"
                className="comms-img rounded-img bg-white"
              />
            </div>
          </div>
          <div className="col-md-6  wow-box wow fadeInLeft">
            <div className="full paddding_left_15 bg-white">
              <div className="heading_main text_align_left">
                <h2>
                  <span className="theme_color">Dalbit Oil</span>
                </h2>
              </div>
            </div>
            <div className="full paddding_left_15 bg-white">
              <p className="orange">
                <span className="underline">
                  Assignment: Public Relations Services for Hand Over Ceremony
                  of Refurbished Paediatric Ward at Kenyatta National Hospital.
                </span>
                <br />
                <br />
                <span className="underline">Tasks:</span>
                <br />
                <br />
                Dalbit Oil as part of its Corporate Citizenship/CSR Programme
                refurbished KNH Paediatrics Ward 3A as part of KNH “adopt a ward
                programme”.
                <br />
                <br />
                Dalbit Oil retained Mugumo to provide public relations services
                for the handover ceremony.
                <br />
                Mugumo provided services covering pre handover activities, the
                ceremony and post handover.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="container layout_padding">
        <div className="row">
          <div className="col-md-6  wow-box wow fadeInLeft">
            <div className="full paddding_left_15 bg-white">
              <div className="heading_main text_align_left">
                <h2>
                  <span className="theme_color">UNWOMEN</span>
                </h2>
              </div>
            </div>
            <div className="full paddding_left_15 bg-white">
              <p className="orange">
                <span className="underline">
                  Assignment: Strategic Agency Services for HeForShe Campaign.
                </span>
                <br />
                <br />
                <span className="underline">Tasks:</span>
                <br />
                <br />
                HeForShe Campaign was a United Nations global campaign and was
                implemented by UN Women.
                <br />
                The campaign was aimed at promoting gender equality for both men
                and women as part of human right issues.
                <br />
                UN Women retained Mugumo to provide the following services:
                <br />
                <br />
                1. Media relations and management
                <br />
                2. Productions (Print; Radio and TV – infomercials and
                documentary).
                <br />
                3. Digital media creations and transmissions.
                <br />
                4. Coordinating with Presidential Strategic Communication Unit
                (PSCU) on the launch day activities.
                <br />
                5. Production and distribution of the visibility material.
                <br />
                6. Overseeing the publicity campaign.
              </p>
            </div>
          </div>
          <div className="col-md-6 wow-box wow fadeInLeft">
            <div className="full text_align_right_img">
              <img
                src={heForShe}
                alt="he for she"
                className="comms-img rounded-img bg-white"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-md-6 wow-box wow fadeInLeft">
            <div className="full text_align_right_img">
              <img
                src={kncci}
                alt="kncci"
                className="comms-img rounded-img bg-white"
              />
            </div>
          </div>
          <div className="col-md-6  wow-box wow fadeInLeft">
            <div className="full paddding_left_15 bg-white">
              <div className="heading_main text_align_left">
                <h2>
                  <span className="theme_color">Corporate Re-launches</span>
                </h2>
              </div>
            </div>
            <div className="full paddding_left_15 bg-white">
              <p className="orange">
                <span className="underline">
                  Assignment: Coordinating Kenya National Chamber of Commerce
                  and Industry Relaunch.
                </span>
                <br />
                <br />
                <span className="underline">Tasks:</span>
                <br />
                <br />
                To celebrate their rebranding, KNCCI organized a relaunch
                ceremony and H.E. the President as the Patron was the guest of
                honour.
                <br />
                KNCCI retained Mugumo to coordinate events and activities
                leading to the launch day; the launch ceremony and post event.
                Services provided included:
                <br />
                <br />
                1. Activation activities in selected in Cities/major towns prior
                to the launch.
                <br />
                2. Media relations and management.
                <br />
                3. Productions (Print; Radio and TV – infomercials and
                documentary).
                <br />
                4. Digital media creations and transmissions.
                <br />
                5. Coordinating with Presidential Strategic Communication Unit
                (PSCU) on the launch day activities.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-md-6  wow-box wow fadeInLeft">
            <div className="full paddding_left_15 bg-white">
              <div className="heading_main text_align_left">
                <h2>
                  <span className="theme_color">Press Relations</span>
                </h2>
              </div>
            </div>
            <div className="full paddding_left_15 bg-white">
              <p className="orange">
                <span className="underline">Tasks:</span>
                <br />
                <br />
                Mugumo has been retained by KNCCI as their PR Agency.
                <br />
                Our assignment included :-
                <br />
                1. Media relations and management.
                <br />
                2. Production (Print - advertorial and editorial).
                <br />
                3. Productions (Radio and TV- infomercials and documentaries).
              </p>
            </div>
          </div>
          <div className="col-md-6 wow-box wow fadeInLeft">
            <div className="full text_align_right_img">
              <img
                src={press}
                alt="press"
                className="comms-img rounded-img bg-white"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="container layout_padding">
        <div className="row">
          <div className="col-md-6 wow-box wow fadeInLeft">
            <div className="full text_align_right_img">
              <img
                src={unWomen}
                alt="un women"
                className="comms-img rounded-img bg-white"
              />
            </div>
          </div>
          <div className="col-md-6  wow-box wow fadeInLeft">
            <div className="full paddding_left_15 bg-white">
              <div className="heading_main text_align_left">
                <h2>
                  <span className="theme_color">Crisis Management</span>
                </h2>
              </div>
            </div>
            <div className="full paddding_left_15 bg-white">
              <p className="orange">
                <span className="underline">
                  Situation Room for Dealing with Political Violence
                </span>
                <br />
                <br />
                In 2007 general elections, Kenya experienced the worst election
                violence in its history. To mitigate the situation in the
                succeeding 2013 general elections, UnWomen , assembled a team of
                preeminent women to prevent conflict during elections.
                <br />
                <br />A Situation Room was established and provided women and
                youth organizations with a platform to respond rapidly to
                election-related incidents through a coordinated system. The
                Situation Room was credited with resolving 1200 real time
                election related situations.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-md-6  wow-box wow fadeInLeft">
            <div className="full paddding_left_15 bg-white">
              <div className="heading_main text_align_left">
                <h2>
                  <span className="theme_color">Resettlement</span>
                </h2>
              </div>
            </div>
            <div className="full paddding_left_15 bg-white">
              <p className="orange">
                <span className="underline">
                  Resettling of Internally Displaced Persons.
                </span>
                <br />
                <br />
                The 2007/08 election violence left many Kenyans internally
                displaced.
                <br />
                The Government of Kenya engaged in a resettlement exercise to
                provide decent accommodation for the internally displaced
                persons.
                <br />
                The resettlement programme also entailed equipping the resettled
                persons with life skills so as enable them re-establish
                themselves financially.
                <br />
                Mugumo Communication was retained by the Ministry of Special
                Programmes to oversee IDPs resettlement in the Rift Valley.
              </p>
            </div>
          </div>
          <div className="col-md-6 wow-box wow fadeInLeft">
            <div className="full text_align_right_img">
              <img
                src={resettling}
                alt="resettling"
                className="comms-img rounded-img bg-white"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="back-button">
        <Button
          caption="Back To Our Practice Areas"
          onClick={() => onClick('practice')}
        />
      </div>
    </div>
  );
};

export default Communications;
