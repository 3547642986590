import React from 'react';

import _1 from '../../img/customer/1.png';
import _2 from '../../img/customer/2.png';
import _3 from '../../img/customer/3.png';

interface IService {
  onClick: (source: string) => {};
}

const Customer: React.FC<IService> = ({ onClick }) => {
  return (
    <div id="customer" className="layout_padding">
      <div className="row">
        <div className="col-md-12">
          <div className="full">
            <div className="heading_main text_align_center">
              <h2>
                <span className="orange">Customer Services</span>
              </h2>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-md-6 layout_padding wow-box wow fadeInLeft">
            <div className="full paddding_left_15 bg-white">
              <div className="heading_main text_align_left">
                <h2>
                  <span className="theme_color">24 hours call service</span>
                </h2>
              </div>
            </div>
          </div>
          <div className="col-md-6 wow-box wow fadeInLeft">
            <div className="full text_align_right_img">
              <img
                src={_1}
                alt="call service"
                className="intro-img bg-white rounded-img"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-md-6">
            <div className="full text_align_right_img wow-box wow fadeInLeft">
              <img
                src={_2}
                alt="customer services"
                className="intro-img bg-white rounded-img"
              />
            </div>
          </div>
          <div className="col-md-6 layout_padding wow-box wow fadeInLeft">
            <div className="full paddding_left_15 bg-white">
              <div className="heading_main text_align_left">
                <h2>
                  <span className="theme_color">Customer Service</span>
                </h2>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-md-6 layout_padding wow-box wow fadeInLeft">
            <div className="full paddding_left_15 bg-white">
              <div className="heading_main text_align_left">
                <h2>
                  <span className="theme_color">Delivery</span>
                </h2>
              </div>
            </div>
          </div>
          <div className="col-md-6 wow-box wow fadeInLeft">
            <div className="full text_align_right_img">
              <img
                src={_3}
                alt="delivery"
                className="intro-img bg-white rounded-img"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Customer;
