import React from 'react';

interface ITile {
  leftImage: string;
  rightImage?: string;
}

const Tile: React.FC<ITile> = ({ leftImage, rightImage }) => {
  return (
    <div className="container">
      <div className="row">
        <div className="col-md-6 layout_padding">
          <div className="full text_align_right_img  wow-box wow fadeInLeft">
            <img src={leftImage} alt="other" className="tile-img rounded-img" />
          </div>
        </div>
        <div className="col-md-6 layout_padding">
          <div className="full text_align_right_img  wow-box wow fadeInLeft">
            {rightImage && (
              <img
                src={rightImage}
                alt="other"
                className="tile-img rounded-img"
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Tile;
