import React from 'react';

import _1 from '../../img/vehicle/1.png';
import _2 from '../../img/vehicle/2.png';
import _3 from '../../img/vehicle/3.png';
import _4 from '../../img/vehicle/4.png';
import _5 from '../../img/vehicle/5.png';
import _6 from '../../img/vehicle/6.png';

import Tile from '../Tiles';
import Button from '../Button';

interface IVehicle {
  onClick: (source: string) => {};
}

const Vehicle: React.FC<IVehicle> = ({ onClick }) => {
  return (
    <div id="vehicle">
      <Tile leftImage={_1} rightImage={_2} />
      <Tile leftImage={_3} rightImage={_4} />
      <Tile leftImage={_5} rightImage={_6} />
      <div className="back-button">
        <Button
          caption="Back To Branding & Creativity"
          onClick={() => onClick('branding')}
        />
      </div>
    </div>
  );
};

export default Vehicle;
